const CreateSaleOrder = {
  path: "",
  name: "",
  redirect: "/create-sale-order",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/create-sale-order",
      redirect: "/create-sale-order",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Create Sale Order",
      meta: {
        requiresAuth: true,
        activeUrl: "/create-sale-order",
        parent: "/create-sale-order",
        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/createSaleOrder"),
          meta: {
            requiresAuth: true,
            activeUrl: "/create-sale-order",
            parent: "/create-sale-order",

            lv: 2,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default CreateSaleOrder;
