import Vue from "vue";
import editor from "@tinymce/tinymce-vue";
import "tinymce/tinymce";
import "tinymce/themes/silver/theme";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/paste";
import "tinymce/plugins/autoresize";
// import Footer from "./components/FooterAction.vue";
import Footer from "../components/FooterAction.vue";
import SideBarFilter from "../components/SideBarFilter.vue";
import Pagination from "../components/Pagination.vue";
import HeaderPanel from "../components/HeaderPanel.vue";
import InputText from "../components/inputs/InputText.vue";
import InputTextArea from "../components/inputs/InputTextArea.vue";
import InputSelect from "../components/inputs/InputSelect.vue";
import TextEditorsTiny from "../components/inputs/TextEditorsTiny.vue";
import AutoComplete from "../components/inputs/Autocomplete.vue";
import InputDatePickerFilter from "../components/inputs/InputDatePickerFilter.vue";
Vue.component("editor", editor);
Vue.component("FooterAction", Footer);
Vue.component("SideBarFilter", SideBarFilter);
Vue.component("Pagination", Pagination);
Vue.component("HeaderPanel", HeaderPanel);
Vue.component("InputText", InputText);
Vue.component("InputTextArea", InputTextArea);
Vue.component("InputSelect", InputSelect);
Vue.component("TextEditorsTiny", TextEditorsTiny);
Vue.component("AutoComplete", AutoComplete);
Vue.component("InputDatePicker", InputDatePickerFilter);
// export default LocalComponents;
