import {
  SaleOrder,
  Upload,
  CreateSaleOrder
} from "../path";

function configRoutes() {
  return [
    {
      path: "/",
      name: "Login",
      component: () => import("@/views/pages/Login"),
    },
    {
      path: "/home",
      name: "Home",
      redirect: "/sale-order",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [
        SaleOrder,
        Upload,
        CreateSaleOrder,
        {
          path: "/welcome",
          name: "Welcome",
          redirect: "/sale-order",
          component: () => import("@/views/pages/Welcome"),
          meta: { requiresAuth: true },
        },
      ],
    },

    {
      path: "/500",
      name: "Page500",
      component: () => import("@/views/pages/Page500"),
    },
    {
      path: "/403",
      name: "Page403",
      component: () => import("@/views/pages/Page403"),
    },
    {
      path: "*",
      name: "Page404",
      component: () => import("@/views/pages/Page404"),
    },
  ];
}
export default configRoutes;
