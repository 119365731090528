import Vue from "vue";
import Router from "vue-router";
// import RouterPermission from "./middleware/permission";


import configRoutes from "./routes";
Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;
  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  // if (to.meta.requiresAuth) {
  //   const middleware = Array.isArray(to.meta.middleware)
  //     ? to.meta.middleware
  //     : RouterPermission;

  //   const context = {
  //     from,
  //     next,
  //     router,
  //     to,
  //   };
  //   const nextMiddleware = nextFactory(context, middleware, 1);

  //   return middleware({ ...context, next: nextMiddleware });
  // }

  return next();
});

export default router;
