import Vue from 'vue'
import Vuex from 'vuex'
import moduleConnectAPI from './connectAPI/index'
import auth from './modules/auth'
import theme from './modules/theme'
import saleOrder from './modules/saleOrder'
import upload from './modules/upload'
import createSaleOrder from './modules/createSaleOrder'

Vue.use(Vuex)
import axios from 'axios'
import VueCookies from 'vue-cookies'
import swal2 from 'sweetalert2'
axios.interceptors.request.use(function (config) {
  config.headers.Authorization = Vue.$cookies.isKey('back_office_admin_sale_order_token')
    ? `Bearer ${Vue.$cookies.get("back_office_admin_sale_order_token")}`
    : ''
  config.headers.common['API-KEY'] = process.env.VUE_APP_API_KEY
  return config
})

axios.interceptors.response.use(undefined, (error) => {
  if (error.response.data.message == 'Permission denied') {
    window.location.href = '/403'
  }
  if (error.response.status == 400) {
    swal2.fire('Internal Server Error', 'Please try again Leter', 'error')
  }
  if (error.response.status == 401) {
    swal2
      .fire('No access to information', 'Please try again', 'error')
      .then(() => {
        let cookiesDomain = window.location.hostname
        VueCookies.remove('back_office_admin_sale_order_token', null)
        VueCookies.remove('permission_menu_admin_sale_order', null)
        VueCookies.remove('back_office_admin_sale_order_permission', null)
        VueCookies.remove('', null)
        window.location.href = '/'
      })
  }
  if (error.response.status === 500) {
    swal2
      .fire('Internal Server Error.', 'Please try again', 'error')
      .then(() => {
        let cookiesDomain = window.location.hostname
        VueCookies.remove('back_office_admin_sale_order_token', null)
        VueCookies.remove('permission_menu_admin_sale_order', null)
        VueCookies.remove('back_office_admin_sale_order_permission', null)
        window.location.href = '/'
      })
  }
  return error
})
Vue.prototype.$Axios = axios
export const store = new Vuex.Store({
  namespaced: true,
  state: {
    formatDateNew: 'DD/MM/YYYY',
    formatDate: 'DD MMM YYYY',
    formatDateTime: 'DD MMM YYYY HH:mm',
    formatTime: 'HH:mm',
    screenSize: {
      width: 0,
      height: 0
    },
    mainLoading: false,
  },
  mutations: {
    setScreenSize(state, screenSize) {
      state.screenSize.width = screenSize.width
      state.screenSize.height = screenSize.height
    },
    UPDATE_MAIN_LOADING: (state, payload) => {
      state.mainLoading = payload;
    }
  },
  actions: {
    setMainLoading(context, payload){
      context.commit("UPDATE_MAIN_LOADING", payload);
    },
  },
  getters: {
    getMainLoading: state => {
      return state.mainLoading;
    }
  },
  modules: {
    moduleConnectAPI,
    auth,
    theme,
    saleOrder,
    upload,
    createSaleOrder
  // permission,
  }
})
