<template>
  <b-sidebar
    id="sidebarFilter"
    v-model="isShowSidebar"
    title="FILTER"
    aria-labelledby="sidebar-no-header-title"
    backdrop
    shadow
    right
    ref="sidebarFilter"
  >
    <template #header>
      <b-row class="header-filter no-gutters">
        <b-col class="text-filter"> Filter </b-col>
        <b-col class="text-right" v-if="!hideClearFilter">
          <button class="btn-clear" @click.prevent="clearFilter">
            x <span class="text-under">Clear Filter</span>
          </button>
        </b-col>
      </b-row>
    </template>
    <div class="px-3 pb-3">
      <p v-if="!hideSearchBar" class="font-weight-bold mt-3 mb-2 p-black">
        Search
      </p>
      <b-input-group v-if="!hideSearchBar">
        <b-form-input
          type="search"
          class="search-bar"
          :placeholder="placeholder"
          v-model="filter.search"
        >
        </b-form-input>
      </b-input-group>
      <template>
        <div class="mt-3">
          <slot name="filter-option"></slot>
          <b-form-group v-if="hideStatusFilter">
            <template #label>
              <b>Status:</b><br />
              <b-form-checkbox
                v-model="StatusSelectedAll"
                aria-describedby="Status"
                aria-controls="Status"
                @change="StatusToggleAll"
              >
                Select All
              </b-form-checkbox>
            </template>

            <template v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                class="ml-4"
                id="checkbox-group-2"
                stacked
                v-model="filter.status"
                :aria-describedby="ariaDescribedby"
              >
                <b-form-checkbox :value="1" id="Active">Active</b-form-checkbox>
                <b-form-checkbox :value="0" id="Inactive"
                  >Inactive</b-form-checkbox
                >
              </b-form-checkbox-group>
            </template>
          </b-form-group>
        </div>
      </template>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            id="handle-search-filter"
            class="text-body rounded-pill btn-main"
          >
            Search
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  props: {
    filter: {
      required: true,
      type: Object,
    },
    placeholder: {
      required: false,
      type: String,
    },
    hideStatusFilter: {
      required: false,
      default: true,
    },
    hideSearchBar: {
      required: false,
      default: false,
    },
    hideClearFilter: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      Status: [1, 0],
      StatusSelected: [],
      StatusSelectedAll: false,
      isShowSidebar: false,
    };
  },
  watch: {
    "filter.status"(newValue) {
      if (newValue.length === 0) {
        this.StatusSelectedAll = false;
      } else if (newValue.length === this.Status.length) {
        this.StatusSelectedAll = true;
      } else {
        this.StatusSelectedAll = false;
      }
    },
  },
  methods: {
    show() {
      if (!this.hideStatusFilter) {
      } else {
        this.filter.status =
          typeof this.filter.status == "number" ? [] : [...this.filter.status];
      }
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    StatusToggleAll(checked) {
      this.filter.status = checked ? [0, 1] : [];
    },
    clearFilter() {
      this.$emit("clearFilter");

      // this.hide();
    },

    submitAction() {
      if (this.filter.page) {
        this.filter.page = 1;
      }
      this.$emit("searchAll", this.filter);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: unset;
}
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0.5rem;
}
.text-filter {
  font-weight: 600;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}

input.search-bar::placeholder {
  text-transform: capitalize;
}
.form-group {
  margin-bottom: 0.5rem !important;
}
</style>
